<template>
  <b-card title="Додатковий функціонал">
    <b-row>
      <b-col sm="12" class="text-right">
        <b-btn-group>
          <b-button size="sm" variant="success" @click="openModal"><fa icon="plus" /></b-button>
        </b-btn-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" class="mt-2">
        <table class="table">
          <thead>
          <tr>
            <th>{{ $t('name') }}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td>{{ item.name }}</td>
            <td>
              <b-btn-group>
                <b-button size="sm" variant="warning"><fa icon="pencil-alt" /></b-button>
                <b-button size="sm" variant="danger"><fa icon="times" /></b-button>
              </b-btn-group>
            </td>
          </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
    <b-modal id="additional-modal" size="sm" hide-footer title="Add">
      <div class="form-group">
        <label for="name">{{ $t('name') }}</label>
        <input type="text" v-model="form.name" required class="form-control form-control-sm">
      </div>
      <b-button size="sm" variant="primary" @click="save">{{ $t('save') }}</b-button>
    </b-modal>
  </b-card>
</template>

<script>
import axios from 'axios'
import Form from 'vform'
export default {
  data: () => ({
    items: null,
    form: new Form({
      name: ''
    })
  }),
  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.get(apiUrl + 'operations/types/additional').then(resp => {
        if (resp.data) {
          this.items = resp.data
        }
      })
    },
    save () {
      if (this.form.id) {
        // update
      } else {
        this.create()
      }
    },
    async create () {
      this.$root.$refs.loading.start()
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await this.form.post(apiUrl + 'operations/types/additional').then(resp => {
        if (resp.data) {
          this.getData()
          this.form.reset()
          this.$bvModal.hide('additional-modal')
          this.$root.$refs.loading.finish()
        }
      })
    },
    openModal () {
      this.$bvModal.show('additional-modal')
    }
  }
}
</script>
